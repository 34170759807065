import { RouteObject, createBrowserRouter, RouterProvider } from 'react-router-dom';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import storage from '@/utils/storage';
// import { useMe } from '@/features/auth/api/getUser';

export const AppRoutes = () => {
  const user = storage.getToken();
  // const { data: user, isLoading } = useMe({ venueId: 4, config: { enabled: !!storage.getToken() } });

  const routes: RouteObject[] = user ? protectedRoutes : publicRoutes;

  const router = createBrowserRouter([...routes]);

  return <RouterProvider router={router} />;
};
