import storage from '@/utils/storage';
import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, queryClient, QueryConfig } from '@/lib/react-query';
import { User } from '@/types';

const QUERY_KEY = 'me';
export const getWhoAmI = (venueId?: number | null): Promise<User> => {
  return axios.get(`${venueId ?? 0}/whoami`);
};

type QueryFnType = typeof getWhoAmI;

type UseUserOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useMe = ({ config }: UseUserOptions) => {
  const venueId: number | undefined = queryClient.getQueryData('current_venue_id');
  console.log("venueId", venueId, queryClient)
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY, venueId],
    queryFn: () => getWhoAmI(venueId),
    ...config,
    enabled: !!venueId && venueId > 0,
  });
};

export const getUser = (): any => {
  const user = storage.getToken();
  return user;
};
