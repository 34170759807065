import { useClientStore } from '@/stores/clientStore';
import {
  DropdownMenu,
  DropdownMenuGroup,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { ChevronsUpDown, MapPinned } from 'lucide-react';
import { useVenues } from '@/features/venue/api/get-venues';
import { Venue } from '@/types';
import { queryClient } from '@/lib/react-query';
import { useClients } from '@/features/clients/api/getClients';
import React from 'react';

export function VenueDropDownMenu() {
  const clientStore = useClientStore;
  const venueId = useClientStore(state => state.venueId);
  const setVenueId = useClientStore(state => state.setVenueId);
  const setClientId = useClientStore(state => state.setClientId);

  const { data: venues } = useVenues({
    config: {
      onSuccess: (venues: Venue[]) => {
        const newClientId = venues?.[0]?.clientId ?? 0;
        if (newClientId !== clientStore.getState().clientId) {
          clientStore.persist.setOptions({ name: `${newClientId}-client-storage` });
          useClientStore.persist.rehydrate();
        }

        if (venues && venues.length > 0 && !venueId) {
          queryClient.setQueryData('current_venue_id', venues[0].venueId);
          setVenueId(venues[0].venueId);
          setClientId(venues[0].clientId);
          clientStore.persist.setOptions({ name: `${venues[0].clientId}-client-storage` });
          useClientStore.persist.rehydrate();
        }
      },
    },
  });
  const clientIds: number[] = [...new Set(venues?.map(venue => venue.clientId) ?? [])];
  const { data: clients = [] } = useClients({ clientIds, config: { enabled: clientIds.length > 0 } });
  const handleVenueChange = (venueId: number) => {
    const venue = venues?.find(venue => venue.venueId === venueId);
    queryClient.setQueryData('current_venue_id', venueId);
    queryClient.invalidateQueries('me');
    setClientId(venue?.clientId ?? 0);
    setVenueId(venueId);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="border rounded-md py-2 px-3 w-full flex items-center bg-accent justify-between">
        <div className="flex gap-x-2 items-center">
          <MapPinned className="w-4 h-4" size={16} />
          <span className="font-semibold text-sm"> {venues?.find(venue => venue.venueId === venueId)?.name ?? 'Inget valt'}</span>
        </div>
        <ChevronsUpDown className="w-4 h-4" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[200px]">
        <DropdownMenuLabel>Anläggning</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {clients.length > 0
          ? clients.map(client => (
              <React.Fragment key={client.clientId}>
                <DropdownMenuLabel>{client.name}</DropdownMenuLabel>
                <DropdownMenuGroup>
                  {venues
                    ?.filter(venue => venue.clientId == client.clientId)
                    .map(venue => (
                      <DropdownMenuCheckboxItem
                        checked={venueId === venue.venueId}
                        onCheckedChange={() => handleVenueChange(venue.venueId)}
                        key={venue.venueId}
                      >
                        {venue.name}
                      </DropdownMenuCheckboxItem>
                    ))}
                </DropdownMenuGroup>
              </React.Fragment>
            ))
          : venues?.map(venue => (
              <DropdownMenuCheckboxItem checked={venueId === venue.venueId} onCheckedChange={() => handleVenueChange(venue.venueId)} key={venue.venueId}>
                {venue.name}
              </DropdownMenuCheckboxItem>
            ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
