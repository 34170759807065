import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Client } from '@/types';
import { getUrlArrayString } from '@/utils/getArrayUrlString';

export const getClients = (clientIds: number[] | undefined): Promise<Client[]> => {
  const clientIdsString = !!clientIds && clientIds?.length > 0 ? getUrlArrayString('clientIds', clientIds) : '';
  return axios.get(`/api/client${!!clientIdsString ? `?${clientIdsString}` : ''}`);
};

type QueryFnType = typeof getClients;

type UseClientsOptions = {
  clientIds?: number[] | undefined;
  config?: QueryConfig<QueryFnType>;
};

export const useClients = ({ clientIds, config }: UseClientsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['clients', ...[clientIds != undefined ? [...clientIds] : []]],
    queryFn: () => getClients(clientIds),
    ...config,
  });
};
