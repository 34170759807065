import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Venue } from '@/types';

export const getVenues = (clientId: number | undefined): Promise<Venue[]> => {
  if(!clientId) {
    return axios.get(`/0/venue`);
  }
  return axios.get(`/0/venue?clientId=${clientId}`);
};

type QueryFnType = typeof getVenues;

type UseVenuesOptions = {
  clientId?: number;
  config?: QueryConfig<QueryFnType>;
};

export const useVenues = ({ clientId, config }: UseVenuesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['venues', clientId],
    queryFn: () => getVenues(clientId),
    ...config,
  });
};
